import React from 'react';
import Button from '../components/Button';
import Layout from '../components/layout/Layout';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"; //highlight-line
// import Seo from "../components/Seo";

export default function BlogArchive({ data }) {
    //highlight-line
    return (
        <Layout key="blog-archive" >

            {/* {data.allWpPage.nodes.map((node, index) => (
                // <Seo key={index + Math.random()} title={node.seo.title} description={node.seo.metaDesc} />
            ))} */}


            {/* start the Blog data */}

            <section id="blog" className="py-40">
                <div className="container mx-auto text-center">
                    <h2 className="text-3xl lg:text-5xl font-semibold">Blog</h2>
                    <div className="grid lg:grid-cols-3 sm:flex-row sm:grid-cols-1 sm:-mx-3 mt-12">
                        {data.allWpPost.nodes.map((node, index) => (
                            <div key={index + Math.random()} className="py-5 flex-1 px-3">

                                <div key={index + Math.random()}
                                    className={`pb-10 rounded-lg border border-solid border-gray-200 mb-8 h-full
overflow-hidden shadow-lg transition duration-500 ease-in-out transform hover:-translate-y-5 hover:shadow-2xl	 cursor-pointer m-auto`}
                                    style={{ boxShadow: '0 10px 28px rgba(0,0,0,.08)' }}
                                >

                                    <Link to={node.uri}>  
                                    {node.featuredImage && 
                                    <GatsbyImage key={index + Math.random()} className="object-cover max-h-56 w-full" image={getImage(node.featuredImage.node.localFile)} alt={node.title} />
}
                                    </Link>
                                    <p className="font-semibold text-xl pt-5 ">
                                        <Link to={node.uri}>{node.title}</Link>
                                    </p>
                                    <p className="pt-6">
                                        <Link to={node.uri}> <Button size="sm">Read More</Button> </Link></p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>





        </Layout>
    )

};



//highlight-start
export const pageQuery = graphql`
{
  allWpPost(sort: {fields: [date], order: DESC}) {
    nodes {
      id
      title
      slug
      uri
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
        }
      }
    }
  }
}

`